<template>
  <div>
    <v-container class="wrapper--my-pictures">
      <div class="crop-wrapper" v-if="$store.state.level == 1">
        <h1>Pepe Moreno</h1>
        <div class="avatar-box v-image__image v-image__image--cover" style="background-image: url(&quot;http://localhost:8080/img/1.e2938115.png&quot;); background-position: center center;"></div>
        <div class="text-center mt-12 stars-box">
          <v-rating
            v-model="rating"
            color="yellow darken-3"
            background-color="grey darken-1"
            empty-icon="$ratingFull"
            half-increments
            hover
            large
          ></v-rating>
        </div>
        <img src="@/assets/images/pages/landingpage/flower.webp">
        <p class="txt-top">The only thing that is absolute is that everything is relative.</p>

      </div>
      <ul class="list-followers" v-if="$store.state.level == 1">
        <li>20 Memory bits</li>
        <li>227 Bio followers</li>
        <li>528 Bio following</li>
      </ul>
      <div class="wrapper-avatars-header" v-if="$store.state.level == 1">
        <span class="text--primary text-center font-weight-medium">
          18 connections
        </span>
        <v-avatar size="40">
          <v-img src="@/assets/images/avatars/1.png"></v-img>
        </v-avatar>
        <v-avatar size="40">
          <v-img src="@/assets/images/avatars/1.png"></v-img>
        </v-avatar>
        <v-avatar size="40">
          <v-img src="@/assets/images/avatars/1.png"></v-img>
        </v-avatar>
        <v-avatar size="40">
          <v-img src="@/assets/images/avatars/1.png"></v-img>
        </v-avatar>
        <v-avatar size="40">
          <v-img src="@/assets/images/avatars/1.png"></v-img>
        </v-avatar>
      </div>
      <v-row v-if="!$store.state.gallery.open" class="row-profile-selector">
        <v-col class="text-center" cols="12">
          <div class="tw-toggle">
            <input id="rdo-personal" type="radio" name="toggle" value="false" :checked="content_type === 0" @click="checkContentType(0)" />
            <label for="rdo-personal" class="toggle toggle-yes">
              <v-icon :class="{'alternate-icon-small': !mdiAccount}" class="mx-auto">
                {{ mdiAccount }}
              </v-icon>
            </label>
            <input id="rdo-both" type="radio" name="toggle" value="-1" @click="checkContentType(-1)" :checked="content_type === -1" />
            <label for="rdo-both" class="toggle toggle-yes">
              <v-icon :class="{'alternate-icon-small': !mdiAccountMultiple}" class="mx-auto">
                {{ mdiAccountMultiple }}
              </v-icon>
            </label>
            <input id="rdo-professional" type="radio" name="toggle" value="true" @click="checkContentType(1)" :checked="content_type === 1" />
            <label for="rdo-professional" class="toggle toggle-yes">
              <v-icon :class="{'alternate-icon-small': !mdiAccountTie}" class="mx-auto">
                {{ mdiAccountTie }}
              </v-icon>
            </label>
            <span></span>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="!$store.state.gallery.open">
        <div class="d-flex justify-center wrapper-menu">
          <!-- ### Menu Mobile ### -->
          <template v-if="$vuetify.breakpoint.mdAndDown">
            <div class="inner-wrapper-menu" v-if="active">
              <v-list class="mobile--menu-list">
                <v-list-group
                  :value="false"
                >
                  <template v-slot:activator>
                    <v-list-item-title>Categories</v-list-item-title>
                  </template>

                  <template v-if="!content_type || content_type === -1">
                  <div v-for="(item, i) in categories" :key="`system-${i}`">
                    <v-list-item class="single_item" link v-if="!item.children" @click="searchCategory(item.id, 1)"><span>-</span> {{ item.name }}</v-list-item>
                    <div v-else>
                      <v-list-group
                        :value="false"
                        no-action
                        sub-group
                      >
                        <template v-slot:activator>
                          <v-list-item-title @click="searchCategory(item.id, 1)">{{ item.name }}</v-list-item-title>
                        </template>
                        <div v-for="(itemL1) in item.children" :key="`system-L-${itemL1.id}`">
                          <v-list-item class="single_item" link v-if="!itemL1.children" @click="searchCategory(itemL1.id, 1)"><span>-</span> {{ itemL1.name }}</v-list-item>
                          <v-list-group
                            :value="false"
                            no-action
                            sub-group
                            v-else
                          >
                            <template v-slot:activator>
                              <v-list-item-title @click="searchCategory(itemL1.id, 1)">{{ itemL1.name }}</v-list-item-title>
                            </template>
                            <v-list-item class="single_item" link v-for="(itemL2, index) in itemL1.children" :key="`system-L2-${index}`" @click="searchCategory(itemL2.id, 1)"><span>-</span> {{ itemL2.name }}</v-list-item>
                            <v-divider></v-divider>
                          </v-list-group>
                        </div>
                        <v-divider></v-divider>
                      </v-list-group>
                    </div>
                  </div>
                </template>

                <template v-if="content_type || content_type === -1">
                  <div v-for="(item, i) in professionalCategories" :key="`system-${i}`">
                    <v-list-item class="single_item" link v-if="!item.children" @click="searchCategory(item.id, 1)"><span>-</span> {{ item.name }}</v-list-item>
                    <div v-else>
                      <v-list-group
                        :value="false"
                        no-action
                        sub-group
                      >
                        <template v-slot:activator>
                          <v-list-item-title @click="searchCategory(item.id, 1)">{{ item.name }}</v-list-item-title>
                        </template>
                        <div v-for="(itemL1) in item.children" :key="`system-L-${itemL1.id}`">
                          <v-list-item class="single_item" link v-if="!itemL1.children" @click="searchCategory(itemL1.id, 1)"><span>-</span> {{ itemL1.name }}</v-list-item>
                          <v-list-group
                            :value="false"
                            no-action
                            sub-group
                            v-else
                          >
                            <template v-slot:activator>
                              <v-list-item-title @click="searchCategory(itemL1.id, 1)">{{ itemL1.name }}</v-list-item-title>
                            </template>
                            <v-list-item class="single_item" link v-for="(itemL2, index) in itemL1.children" :key="`system-L2-${index}`" @click="searchCategory(itemL2.id, 1)"><span>-</span> {{ itemL2.name }}</v-list-item>
                            <v-divider></v-divider>
                          </v-list-group>
                        </div>
                        <v-divider></v-divider>
                      </v-list-group>
                    </div>
                  </div>
                </template>
                  <v-divider></v-divider>
                  <v-list-item class="single_item" v-if="!content_type" @click="searchCategory(-1, 1)"
                    v-bind:class="{ 'active': categorySelected === '' }">
                    - All
                  </v-list-item>

                  <v-list-item link class="single_item" v-if="content_type !== -1" @click="$store.commit('showCustomCategories', true);"
                    v-bind:class="{ 'active': categorySelected === '' }">
                    - Customize
                  </v-list-item>
                </v-list-group>
              </v-list>
            </div>
          </template>
          <div v-if="!$vuetify.breakpoint.mdAndDown">
            <ul class="list-menu clearfix" v-if="!content_type || content_type === -1">
              <li v-for="(item) in categories" :key="`system-${item.id}`">
                <v-btn @click="searchCategory(item.id, 1)">
                  {{ item.name }}
                  <v-icon v-if="item.children"
                    :class="{'alternate-icon-small': !mdiChevronDown}"
                    class="mx-auto"
                  >
                    {{ mdiChevronDown }}
                  </v-icon>
                </v-btn>
                <ul v-if="item.children">
                  <li v-for="(itemL1) in item.children" :key="`system-L-${itemL1.id}`">
                    <span @click="searchCategory(itemL1.id, 1)">
                      {{ itemL1.name }}
                      <v-icon v-if="itemL1.children"
                        :class="{'alternate-icon-small': mdiChevronDown}"
                        class="mx-auto arrow-icon"
                      >
                        {{ mdiChevronDown }}
                      </v-icon>
                    </span>
                    <ul v-if="itemL1.children">
                      <li v-for="(itemL2, index) in itemL1.children" :key="`system-L2-${index}`">
                        <span @click="searchCategory(itemL2.id, 1)">{{ itemL2.name }}</span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li v-if="!content_type">
                <v-btn @click="searchCategory(-1, 1)" v-bind:class="{ 'active': categorySelected === '' }" elevation="2">All</v-btn>
              </li>
              <li v-if="content_type !== -1">
                <v-btn @click="$store.commit('showCustomCategories', true);" elevation="2">
                  Customize
                </v-btn>
              </li>
            </ul>

            <ul class="list-menu clearfix" v-if="content_type || content_type === -1">
              <li v-for="(item) in professionalCategories" :key="`system-${item.id}`">
                <v-btn @click="searchCategory(item.id, 1)">
                  {{ item.name }}
                  <v-icon v-if="item.children"
                    :class="{'alternate-icon-small': !mdiChevronDown}"
                    class="mx-auto"
                  >
                    {{ mdiChevronDown }}
                  </v-icon>
                </v-btn>
                <ul v-if="item.children">
                  <li v-for="(itemL1) in item.children" :key="`system-L-${itemL1.id}`">
                    <span @click="searchCategory(itemL1.id, 1)">
                      {{ itemL1.name }}
                      <v-icon v-if="itemL1.children"
                        :class="{'alternate-icon-small': mdiChevronDown}"
                        class="mx-auto arrow-icon"
                      >
                        {{ mdiChevronDown }}
                      </v-icon>
                    </span>
                    <ul v-if="itemL1.children">
                      <li v-for="(itemL2, index) in itemL1.children" :key="`system-L2-${index}`">
                        <span @click="searchCategory(itemL2.id, 1)">{{ itemL2.name }}</span></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li v-if="content_type">
                <v-btn @click="searchCategory(-1, 1)" v-bind:class="{ 'active': categorySelected === '' }" elevation="2">All</v-btn>
              </li>
              <li v-if="content_type !== -1">
                <v-btn @click="$store.commit('showCustomCategories', true);" elevation="2">
                  Customize
                </v-btn>
              </li>
            </ul>
          </div>
        </div>
      </v-row>
      <v-row>
        <v-col class="text-subtitle-1 text-center wrapper-buttons" cols="12">
          <ul class="list-icons">
            <li @click="setRandom()" :class="{'active': order === 'random'}">
              <v-tooltip top color="#000000" max-width="200px">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :class="{'alternate-icon-small': !mdiAllInclusive}"
                    class="mx-auto"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ mdiAllInclusive }}
                  </v-icon>
                </template>
                <span>Random View</span>
              </v-tooltip>
            </li>
            <li @click="slider_view = !slider_view" :class="{'active': slider_view}">
              <v-tooltip top color="#000000" max-width="200px">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :class="{'alternate-icon-small': !mdiViewCarousel}"
                    class="mx-auto"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ mdiViewCarousel }}
                  </v-icon>
                </template>
                <span>Slider View</span>
              </v-tooltip>
            </li>
          </ul>
        </v-col>
      </v-row>
      <!-- MODALS -->
      <ModalUpload v-if="$store.state.modal_upload" :content_type="content_type" :categories="content_type ? professionalCategories : categories" :categoriesArray="categoriesArrayId" @getCategories="getCatgContentType" @newFile="newFile()" @closingModal="modalClose(true)" />
      <ModalDetails :actions="true" v-if="modals.content" :categoriesId="categoriesArrayId" :categoriesName="categoriesArrayName" :modals="modals" @closingModal="modalClose" @openEdit="openEdit" @openZoom="openZoom" />
      <ModalEdit v-if="$store.state.modal_edit" :content_type="content_type" :categories="content_type ? professionalCategories : categories" :categoriesArray="categoriesArrayId" :tagsFull="tags" :modals="modals" @closingModal="modalClose(true)" @getCategories="getCatgContentType" />
      <ModalZoom v-if="modals.zoom" :modals="modals" @closingModal="modalClose" />
      <ModalCustomCategories v-if="$store.state.customCategories" :content_type="content_type" :categories="content_type ? professionalCategories_full : categories_full" :categoriesArray="categoriesArray" @getCategories="getCatgContentType" />
      <!-- ###### -->

      <v-row justify="center" class="mb-6">
        <stack v-if="active"
          :column-min-width="300"
          :gutter-width="15"
          :gutter-height="15"
          monitor-images-loaded
          ref="stack"
        >
           <v-container style="height: 400px;" v-if="!files.length">
            <v-row
              class="fill-height"
              align-content="center"
              justify="center"
            >
              <v-col class="text-subtitle-1 text-center" cols="12" v-if="!loading">There are no content to show.</v-col>
              <v-progress-circular
                :size="70"
                :width="7"
                color="primary"
                indeterminate
                v-if="loading"
              ></v-progress-circular>
            </v-row>
          </v-container>

          <template v-if="slider_view">
            <stack-item
              v-for="(file, i) in files"
              :key="`files-${i}`"
              style="transition: transform 300ms"
              @click.native="showModal(file);modals.file.created_at = file.created_at.split('T')[0];"
            >
              <v-card>
                <div class="caption-style">
                  <div class="flag-container" v-if="parseInt(file.categories, 10) === 1">
                    <div class="flag">Draft</div>
                  </div>
                  <!-- <v-avatar size="40">
                    <v-img src="@/assets/images/avatars/1.png"></v-img>
                  </v-avatar> -->

                  <div v-if="file.view_type && file.related_files.length > 1">
                    <img v-if="isImage(file.related_files[0])" :src="`${file.related_files[0]}`" :class="`rotate_images__position_${file.rotate_position[0]}`"  />
                    <video width="100%" height="100%" controls v-else>
                      <source :src="modals.file.related_files[0]" type="video/mp4">
                      Your browser does not support the video tag.
                    </video>
                  </div>
                  <div v-else>
                    <v-carousel v-if="file.related_files.length > 1"
                      height="100%" @change="reflow()"
                    >
                      <v-carousel-item
                        v-for="(item,i) in file.related_files"
                        :key="i">
                        <img v-if="isImage(item)" :src="`${item}`" :class="`rotate_images__position_${file.rotate_position[i]}`" />
                        <video width="100%" height="100%" controls v-else>
                          <source :src="item" type="video/mp4">
                          Your browser does not support the video tag.
                        </video>
                      </v-carousel-item>
                    </v-carousel>
                    <div v-else>
                      <img v-if="isImage(file.related_files[0])" :src="`${file.related_files[0]}`" :class="`rotate_images__position_${file.rotate_position[0]}`"  />
                      <video width="100%" height="100%" controls v-else>
                        <source :src="file.related_files[0]" type="video/mp4">
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                  <div class="caption" :data-categories="file.categories">
                    <div class="blur"></div>
                    <div class="caption-text">
                      <h1>{{file.name }}</h1>
                      <p v-html="subString(file.alternative_text)"></p>
                    </div>
                  </div>
                </div>
              </v-card>
            </stack-item>
          </template>
          <template v-if="!slider_view">
            <template v-for="file in files">
              <stack-item
                v-for="(item, i_rlt) in file.related_files"
                :key="`file_${i_rlt+Math.random()}`"
                style="transition: transform 300ms"
                @click.native="showModal(file);modals.file.created_at = file.created_at.split('T')[0];"
              >
                <v-card>
                  <div class="caption-style">
                    <div class="flag-container" v-if="parseInt(file.categories, 10) === 1">
                      <div class="flag">Draft</div>
                    </div>
                    <img :src="`${item}`" :class="`rotate_images__position_${file.rotate_position[0]}`" />
                    <div class="caption" :data-categories="file.categories">
                      <div class="blur"></div>
                      <div class="caption-text">
                        <h1>{{file.name }}</h1>
                        <p v-html="subString(file.alternative_text)"></p>
                      </div>
                    </div>
                  </div>
                </v-card>
              </stack-item>
            </template>
          </template>
        </stack>
      </v-row>
      <v-pagination v-if="files.length"
        v-model="pagination"
        :length="qtdPages"
      ></v-pagination>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { Stack, StackItem } from "vue-stack-grid";
import { mdiChevronDown, mdiCloudUpload, mdiAccountTie, mdiAccount, mdiAccountMultiple, mdiAllInclusive, mdiViewCarousel } from '@mdi/js';

import ModalUpload from '@/components/modals/ModalUpload.vue';
import ModalDetails from '@/components/modals/ModalDetails.vue';
import ModalEdit from '@/components/modals/ModalEdit.vue';
import ModalZoom from '@/components/modals/ModalZoom.vue';
import ModalCustomCategories from '@/components/modals/ModalCustomCategories.vue';

export default {
  name: "Grid",
  directives: {
  },
  components: {
    ModalCustomCategories,
    ModalUpload,
    ModalEdit,
    ModalDetails,
    ModalZoom,
    Stack,
    StackItem,
  },
  data: () => ({
    rating: 4.5,
    slider_view: true,
    /* Icons */
    mdiChevronDown,
    mdiCloudUpload,
    mdiAccountTie,
    mdiAccount,
    mdiAccountMultiple,
    mdiAllInclusive,
    mdiViewCarousel,
    /* ---- */
    user: {
      id: 0,
    },
    active: 1,
    logged: true,
    pagination: 1,
    qtdPages: 1,
    limitItems: 30,
    images: [],
    orientation: 'vertical',
    modals: {
      content: false,
      edit: false,
      upload: false,
      zoom: false,
      title: '',
      description: '',
      gallery: true,
      file: {
        created_at: '',
        url: '',
        name: '',
      },
    },
    endpoint: process.env.VUE_APP_ROOT_API,
    fileUploaded: null,
    categories: [],
    categories_full: [],
    categoriesArray: [],
    categoriesArrayId: [],
    categoriesArrayName: [],
    professionalCategories: [],
    professionalCategories_full: [],
    file: {
      url: '',
      related_files: [],
    },
    files: [],
    categorySelected: '',
    cmdCategory: [],
    loading: true,
    resultUpload: false,
    tags: [],
    relatedFiles: [],
    content_type: 0,
    counterFlow: 0,
    months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June',	'July',	'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
    activeYear: null,
    /* Categories */
    message: {
      status: false,
      text: ''
    },
    timer: null,
    timeline: [],
    order: 'asc'
  }),
  watch: {
    pagination(newPage) {
      if(newPage) {
        this.callPagination(this.pagination);
      }
    },
    getFilesStatus(oldStatus, newStatus) {
      this.getFiles();
    }
  },
  computed: {
    reloadCategories() {
      return this.$store.state.reloadCategories;
    },
    getFilesStatus() {
      return this.$store.state.getFiles;
    }
  },
  mounted() {
    this.checkLogin();
  },
  methods: {
    isImage(filePath) {
      return filePath && filePath.indexOf('mp4') < 0;
    },
    setRandom() {
      this.order = this.order === 'asc' ? 'random' : 'asc';
      this.searchCategory(this.categorySelected, 1);
    },
    reflow() {
      if(this.counterFlow > this.files.length) {
        setTimeout(() => {
          this.$refs.stack.reflow();
        }, 500);
      }
      this.counterFlow++;
    },
    getCatgContentType(val) {
      this.content_type = val;
      this.$store.commit('setContentType', val);
      this.getCategories();
    },
    checkContentType(val) {
      this.content_type = val;
      this.$store.commit('setContentType', val);
      this.searchCategory(-1, 1);
    },
    checkLogin() {
      this.$store.commit('setLogin', true);
      this.active = true;
      this.logged =  true;
      this.$store.commit('setUploadBiographies', true);
      this.searchCategory(-1, 1);
    },
    callPagination(page) {
      this.searchCategory(this.categorySelected, page);
    },
    openEdit(evt) {
      const self = this;
      this.modals.content = false;
      self.$set(self.modals, 'file', evt.file);
      this.$store.commit('showEditModal', true);
    },
    openZoom() {
      const self = this;
      self.modalClose(false);
      setTimeout(() => {
        self.modals.zoom = true;
      }, 200);
    },
    modalClose(search) {
      this.$store.commit('showEditModal', false);
      this.$store.commit('showUploadModal', false);
      this.modals.content = false;
      this.fbConverting = false;
      this.modals.zoom = false;
      if(search) {
        this.searchCategory(-1, 1);
      }
    },
    newFile() {
      const self = this;
      self.modalClose(true);
      setTimeout(() => {
        this.$store.commit('showUploadModal', true);
      }, 200);
    },
    subString(value) {
      if(value !== undefined) {
        return value.substring(0, 140) + '...';
      } else {
        return value;
      }
    },
    searchCategory(value, page) {
      this.categorySelected = value !== -1 ? value : '';
      this.files = [];
      this.pagination = page;
      this.loading = true;
      this.getCategories();
    },
    geoCodeReverse() {
      const self = this;
      const geocoder = new google.maps.Geocoder();

      self.maps.currentLocation = {
        lat: results[0].geometry.location.lat(),
        lng: results[0].geometry.location.lng()
      };

      geocoder.geocode({'location': self.maps.searchAddressInput}, (results, status) => {
        if (status === 'OK') {
          console.log(results);
        }
      });
    },
    getCategories() {
      const self = this;
      axios.get(
        `${self.endpoint}/getCategories`, {
        headers : {
          'Authorization': this.$store.state.jwtToken
        }
      }).then(response => {
        self.categories = [];
        self.categories_full = [];
        self.categoriesArray = [];
        self.categoriesArrayId = [];
        self.categoriesArrayName = [];
        self.professionalCategories_full = [];
        self.professionalCategories = [];
        self.active_menu_categories = response.data.active_menu_categories;
        response.data.content.forEach(value => {
          if(value.type) {
            if (!value.content_type) {
              if(value.active) {
                self.categories.push(value);
                if(self.content_type === 0) {
                  self.categoriesArrayId.push(value.id);
                  self.categoriesArrayName.push(value.name);
                }
              }
              self.categories_full.push(value);
              self.categoriesArray.push(value.name);
            } else {
              if(value.active) {
                self.professionalCategories.push(value);
                if(self.content_type === 1) {
                  self.categoriesArrayId.push(value.id);
                  self.categoriesArrayName.push(value.name);
                }
              }
              self.professionalCategories_full.push(value)
              self.categoriesArray.push(value.name);
            }
          }
        });
        this.getFiles();
        self.tags = response.data.tags;
      })
    },
    getFiles(year, month) {
      const self = this;
      this.$set(this, 'activeYear', year);
      this.loading = true;
      this.files = [];
      axios.get(
        `${self.endpoint}/getFiles?content_type=${this.content_type >= 0 ? this.content_type : ''}${year ? `&year=${year}` : ''}${month ? `&month=${month}` : ''}&active_menu_categories=${self.active_menu_categories}&category=${this.categorySelected}&limit=${(Math.abs((this.pagination - 1)) * this.limitItems)},${(this.limitItems)}&order=${this.order}`, {
        headers : {
          'Authorization': this.$store.state.jwtToken
        }
      }).then(response => {
        this.loading = false;
        this.files = response.data.content;
        this.timeline = [];
        this.qtdPages = Math.ceil(response.data.total_files / self.limitItems);
        if(self.files[self.files.length - 1]) {
          localStorage.setItem('last_location', self.files[self.files.length - 1].location);
        }
        this.files.forEach(value => {
          const year = value.updated_at.split('T')[0].split('-')[0];
          const month = value.updated_at.split('T')[0].split('-')[1]
          if(this.timeline[year]) {
            if(!this.timeline[year].includes(month)) {
              this.timeline[year].push(month);
            }
          } else {
            this.timeline[year] = [];
            this.timeline[year].push(month);
          }

          if(value.related_files) {
            value.related_files = value.related_files;
          } else {
            value.related_files = [];
          }

          if(value.rotate_position) {
            value.rotate_position = value.rotate_position.split(', ');
          } else {
            value.rotate_position = [];
          }
          this.$emit('update:modals');
        });
      })
    },
    showModal(file) {
      this.$set(this.modals, 'file', file);
      this.fbcounter = 0;
      this.facebookImgUrl = '';
      this.modals.content = true;
    }
  }
};
</script>

<style lang="scss">
.vsg-stack-item {
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.2);

  img {
    width: 100%;
    height: auto;
    border-radius: 12px;
  }
}

.caption-style {
  position: relative;
  padding: 0px;
  width: 100%;
  height: 100%;
  display: table;
}

.caption-style .icon {
  opacity: 0;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 5;
}

.caption-style:hover .caption,
.caption-style:hover .icon {
  opacity: 1;
}

.caption-style:hover img {
  opacity: 1;
  transform: scale(1.15, 1.15);

  &.rotate_images {
    &__position_0 {
      transform: rotate(0) scale(1) scale(1.15, 1.15);
    }

    &__position_1 {
      transform: rotate(90deg) scale(0.75) scale(1.15, 1.15);
    }

    &__position_2 {
      transform: rotate(180deg) scale(1) scale(1.15, 1.15);
    }

    &__position_3 {
      transform: rotate(270deg) scale(0.75) scale(1.15, 1.15);
    }
  }
}

.caption-style img {
  margin: 0px;
  padding: 0px;
  float: left;
  z-index: 0;
}

.caption-style .caption{
  cursor: pointer;
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  -webkit-transition:all 0.45s ease-in-out;
  -moz-transition:all 0.45s ease-in-out;
  -o-transition:all 0.45s ease-in-out;
  -ms-transition:all 0.45s ease-in-out;
  transition:all 0.45s ease-in-out;
  top: 0;
  z-index: 0;
}

.caption-style img{
  -webkit-transition:all 0.25s ease-in-out;
  -moz-transition:all 0.25s ease-in-out;
  -o-transition:all 0.25s ease-in-out;
  -ms-transition:all 0.25s ease-in-out;
  transition:all 0.25s ease-in-out;
}

.caption-style .blur{
  background-color: rgba(0,0,0,0.65);
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  max-height: 1000px;
}

.caption-style .caption-text h1{
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 700;
  line-height: 25px;
  color: #fff !important;
  margin-bottom: 20px;
}
.caption-style .caption-text{
  z-index: 10;
  color: #fff;
  width: 80%;
  height: 100%;
  text-align: center;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.section-lg.section-lg-grid {
  padding-bottom: 0;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.switch-menu {
  position: absolute;
  top: -66px;
  right: 0;
  width: 150px;
  text-align: center;
}

.wrapper-categories {
  padding: 20px;
  width: 100vw;
  max-width: 600px;
  strong {
    font-size: 12px;
    padding: 17px 30px 9px;
    position: relative;
    top: -17px;
    border-bottom: 1px solid;
    display: block;
    text-align: center;
  }
}

.v-btn:not(.v-btn--round).v-size--small {
  height: 36px;
  min-width: 0;
  padding: 1px 5px !important;
  margin: 0;
}

.wrapper-buttons {
  position: relative;
}

.switch-slider-view {
  position: absolute;
  top: 0;
  right: 0;
}

/* Component */
.lst-timeline  {
  position: fixed;
  top: 325px;
  right: calc(((100vw - 1392px) - 250px) / 2);
  z-index: 5;
  margin: 0;
  overflow-y: auto;
  max-height: calc(100vh - 384px);
  bottom: 20px;

  > li {
    list-style: none;
    width: 100px;
    padding: 10px 0 10px 20px;
    margin: 0;
    text-align: center;
    font-size: 14px;

    .v-icon {
      transform: scale(0.8) translate(-4px, -2px)  rotate(0deg);
    }

    &:first-child {
      padding-top: 0;
    }

    a {
      transition: all 0.2s ease;
      position: relative;
      color: #9155fd; // rgba(94, 86, 105, 0.68);
      text-decoration: none;
      font-weight: 500;
      &:hover {
        padding-left: 15px;
      }
      &.active {
        .v-icon {
          transform: scale(0.8) translate(-4px, -2px) rotate(180deg);
        }
      }
    }

    ul {
      margin: 0;
      padding-left: 10px;
      transition: all 0.3s ease-out;
      height: 0;
      overflow: hidden;

      &.active {
        height: auto;
        margin: 5px 0;
      }
    }

    li {
      list-style: none;
      padding: 0;
    }
  }
}

.show-all-years {
  position: fixed;
  top: 270px;
  right: calc(((100vw - 1392px) - 250px) / 2);
  margin: 0;
  width: 100px;
  text-align: center;
  padding: 0 0 5px 0;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
  color: #9155fd;
  text-decoration: none;
  z-index: 2;
}

.list-icons {
  float: right;
  margin: 0;
  padding: 0 !important;
  li {
    float: left;
    list-style: none;
    margin: 0 10px;
    cursor: pointer;

    &.active {
      svg {
        fill: #9155fd
      }
    }
  }
}

.crop-wrapper {
  height: 200px;
  overflow: hidden;
  margin: 20px 0 55px;
  position: relative;
  img {
    width: 100%;
  }

  .avatar-box {
    background-image: url(http://localhost:8080/img/1.e2938115.png);
    background-position: center center;
    width: 160px;
    height: 160px;
    position: absolute;
    top: 9%;
    left: 21px;
    z-index: 10;
  }

  h1 {
    position: absolute;
    top: 14%;
    left: 50%;
    transform: translate(-50%, 50%);
    color: #fff;
    font-size: 40px;
    text-shadow: 0 0 10px rgb(0 0 0 / 30%);
  }

  .stars-box {
    background: none !important;
    position: absolute;
    left: 42%;
    top: 56%;

    * {
      background: none !important
    }
  }
  .txt-top {
    position: absolute;
    left: 50%;
    top: 56%;
    transform: translateX(-50%);
    text-align: center;
    display: block;
    color: #fff;
    text-shadow: 0 0 10px rgb(0 0 0 / 30%);
    font-size: 20px;
    font-weight: bold;
  }
}

.list-followers {
  position: absolute;
  top: 241px;
  left: 5px;
  margin: 0;
  padding: 0 !important;

  li {
    list-style: none;
    float: left;
    padding: 10px;
    font-weight: 500;
    color: rgba(94, 86, 105, 0.87);
  }
}

.wrapper-avatars-header {
  position: absolute;
  right: 15px;
  top: 243px;
  .text--primary {
    position: relative;
    top: 8px;
    right: 20px;
  }
  .v-avatar {
    float: right;
    height: 40px;
    min-width: 40px;
    width: 40px;
  }

  > div:not(:first-child) {
    margin-left: -1rem;
    transform: scale(0.95);
    border: 2px solid #fff;
  }
}

.wrapper--my-pictures {
  position: relative;
}
</style>
