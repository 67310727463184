<template>
  <v-row justify="center">
    <v-dialog class="modal-custom-categories"
      persistent
      max-width="600px"
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.mdAndDown"
    >
      <v-card>
        <v-card-title>
          <v-col cols="12" md="12">
            <span class="text-h5">Manage Categories</span>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-container>
            <CustomCategories :manageCategories='true' :content_type="content_type" :categories="categories" :categoriesArray="categoriesArray" @getCategories="getCatgContentType" />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="$store.commit('showCustomCategories', false);">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import CustomCategories from '@/components/CustomCategoriesTreeView.vue';

  export default {
    props: ['categories', 'categoriesArray', 'content_type'],
    data () {
      return {
        dialog: true
      }
    },
    components: {
      CustomCategories
    },
    methods: {
      getCatgContentType(val) {
        this.$emit('getCategories', val);
      }
    }
  }
</script>

<style scoped lang="scss">
.v-dialog > .v-card > .v-card__text {
  padding: 0;
  margin: 0;
}
.v-card__title {
  padding-bottom: 0 !important;
}
</style>
